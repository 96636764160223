import { defineStore } from 'pinia';
import axios from "axios";

export const viewState = defineStore('viewState', {
    state: () => {
        return {
            isMobile: false,
            isTablet: false,
            showSubMenu: true,
            pageTitle: '',
            menuTitle: '',
            splitPanelSize: 0.225,
            splitPanelSizeTemp: 0.225,
            showDrawer: false,
            drawerName: '',
            drawerTitle: '',
            drawerWidth: 600,
            drawerParameter: {},
            currentPage: null,
            currentDrawer: null,
            landingPage: "/patrol/WorkFlows",

            filterTypeList: [
                {'title': '全部類型', 'key': ''},
                // {'title': '虛擬電廠', 'key': 'VPP', 'class': ''},
                // {'title': 'ESG案場', 'key': 'ESG', 'class': ''},
                // {'title': '電力交易', 'key': 'TRADE', 'class': ''},
                // {'title': '數據分析', 'key': 'AI', 'class': ''},
                {'title': '光電案場', 'key': 'PV', 'class': ''},
                {'title': '沼氣案場', 'key': 'BG', 'class': ''},
                {'title': '熱泵案場', 'key': 'HP', 'class': ''},
                {'title': '儲能案場', 'key': 'ES', 'class': ''},
                {'title': '其它案場', 'key': 'OT', 'class': ''},
                {'title': '焦點案場', 'key': 'FOCUS', 'class': 'm1'},
                {'title': '問題案場', 'key': 'ERROR', 'class': 'm1'},
                {'title': '監控案場', 'key': 'MONITOR', 'class': 'm1'},
                {'title': '第三方案場', 'key': 'THIRD', 'class': 'm1'},
            ],
            dataFieldList: [],
            equipmentList:[],
            caseList:[],
            workerList:[],
            employeesList:[],
            applicationUsers:[],
            selectFilter: '全部類型',
            selectCity: '全部地區',
            selectTarget: '全部案場',
            selectFieldList: [],
            searchKeyword: "",
            hasPVCase: false,
            hasBGCase: false,
            hasHPCase: false,
            hasESCase: false,
            hasOTCase: false,
            windowWidth: window.innerWidth,
        }
    },
    getters: {
        dataFieldWithType(){
            let s = this.dataFieldList
            if (!s) return []
            this.filterTypeList.forEach(f=>{
                if (this.selectFilter === f.title && f.key !== "")
                    s = s.filter(m => m.kind.indexOf(f.key)>=0)
                if (this.selectFilter === '儲能案場')
                    s = this.dataFieldList.filter(f => (f.kind.indexOf('ES')>=0 && f.kind.indexOf('ESG')<0))
            })
            return s.filter(c=>c.name.indexOf(this.searchKeyword) >= 0|| c.address.indexOf(this.searchKeyword) >= 0)
        },
        dataCity(){
            let city = []
            this.dataFieldWithType.forEach(x=>{
                x.city = x.address.substr(0, 3)
                if (x.city !== "" && x.city !== '---' && !city.includes(x.city)){
                    city.push(x.city)
                }
            })
            city.unshift("全部地區")
            return city.sort()
        },
        dataField(){
            let c = this.selectCity === "全部地區" ? "" : this.selectCity
            let f = [].concat(this.dataFieldWithType.filter(f=>f.city.indexOf(c) >= 0))
            f.sort((a, b) => a.name > b.name ? 1: -1)
            f.unshift({'name': '全部案場'})
            return f
        },
        targetInfo(){
            let s = this.selectTarget
            if (this.selectTarget !== "全部案場") return s
            let area = '全部地區'
            let filter = '全部案場'
            let keyword = ''
            if(this.selectCity !== "全部地區") {
                area = this.selectCity
            }
            if(this.selectFilter !== "全部類型"){
                filter = this.selectFilter
            }
            if(this.searchKeyword !== ""){
                keyword ="(" + this.searchKeyword + ")"
            }

            if (area === '全部地區' && filter === '全部案場') {
                return `${filter} ${keyword}`
            }else{
                return `${area}的${filter} ${keyword}`
            }
        },
    },
    actions: {
        handleWindowResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            try {
                this.drawerWidth = window.innerWidth * 0.8;
                if (window.innerWidth >= 969) this.drawerWidth = window.innerWidth * 0.6;
                if (window.innerWidth >= 1500) this.drawerWidth = window.innerWidth * 0.4;
            } catch(err) {
                console.log(err);
            }
            const tabletWidthThreshold = 1440;
            if (this.windowWidth <= tabletWidthThreshold) {
                this.showSubMenu = false;
                this.isTablet = true
            }else{
                this.showSubMenu = true;
                this.isTablet = false
            }
            const mobileWidthThreshold = 769;
            if (this.windowWidth <= mobileWidthThreshold) {
                this.isMobile = true
                this.splitPanelSize = 0.001
            }else{
                this.isMobile = false
                this.splitPanelSize = 250 / window.innerWidth;
            }
        },
        openDrawer(drawerName, drawerTitle, drawerParameter) {
            drawerTitle = drawerTitle || '';
            drawerParameter = drawerParameter || {};
            this.showDrawer = true;
            this.drawerTitle = drawerTitle;
            this.drawerParameter = drawerParameter;
            setTimeout(() => {
                this.drawerName = drawerName;
            }, 330);
        },
        hasRole(check){
            if (!this.currentUser || !this.currentUser.role_list) return false
            return this.currentUser.role_list.indexOf(check) >= 0;
        },
        doSelect(f){
            this.selectTarget = f.name
            this.selectFieldList = []
            if (this.selectTarget === "全部案場"){
                this.selectFieldList = this.dataField.filter(x=>x.id)
            }else{
                this.selectFieldList = this.dataFieldList.filter(x=>x.name === f.name)
            }
            this.hasPVCase = this.selectFieldList.filter(c => c.kind && c.kind.indexOf('PV')>=0).length > 0
            this.hasBGCase = this.selectFieldList.filter(c => c.kind && c.kind.indexOf('BG')>=0).length > 0
            this.hasHPCase = this.selectFieldList.filter(c => c.kind && c.kind.indexOf('HP')>=0).length > 0
            this.hasESCase = this.selectFieldList.filter(c => c.kind && c.kind.indexOf('ES')>=0).length > 0
            this.hasOTCase = this.selectFieldList.filter(c => c.kind && c.kind.indexOf('OT')>=0).length > 0

            localStorage.setItem("view_selectFilter", this.selectFilter)
            localStorage.setItem("view_selectCity", this.selectCity)
            localStorage.setItem("view_selectTarget", this.selectTarget)
        },
        loadFieldData(callback){
            axios.get(`/api/field/list?size=9988`).then((res2) => {
                let d = res2.data.data
                if (Array.isArray(d)) this.dataFieldList = d
                this.dataFieldList.forEach(x=>{
                    x.city = x.address.substr(0, 3)
                })
                this.selectFieldList = this.dataField.filter(x=>x.id)
                this.eventBus.emit('afterLoadFieldData')
                if (typeof callback === "function") callback()
            })
        },
        loadWorkerData(callback) {
            axios.get(`/api/application_user/list?where={"role like":"%worker%"}&size=99999&col=employee_id`).then(res => {
                let ids = res.data.data.map(x => x.employee_id)
                axios.get(`/api/employee/list?where={"end_date <":"2000-1-1","id":"(${ids.join(',')})"}&size=99999`).then(res => {
                    this.workerList = res.data.data
                    this.workerList.forEach(x => {
                        x.src = x.avatar
                        x.tip = x.name
                    })
                    if (typeof callback === "function") callback()
                })
            })
            this.eventBus.emit('afterLoadWorkerData')
        },
        loadEmployeeData(callback){
            axios.get(`/api/employee/list?size=99999`).then(res => {
                this.employeesList = res.data.data
                this.employeesList.forEach(x => {
                    x.src = x.avatar
                    x.tip = x.name
                })
                if (typeof callback === "function") callback()
            })
            this.eventBus.emit('afterLoadEmployeeData')
        },
        loadApplicationUserData(){
            axios.get(`/api/application_user/list?size=99999`).then(res => {
            console.log(res.data);
            this.applicationUsers = res.data.data;
            })
        },
        loadCaseData(){
            axios.get('/api/case_info/list?size=9999').then(res=>{
                console.log(res.data);
                this.caseList = res.data.data
                this.eventBus.on('afterLoadCaseData')
            })
        },
        async loadEquipmentData() {
            const response = await axios.get('/api/case_equipment/list?size=99999');
            const data = response.data;
            if (response.status === 200 && data.message === 'success') {
                this.equipmentList = data.data.filter(item => item.template !== 'data' && item.title !== '新項目');
            } else {
                console.error('Failed to retrieve equipment data.');
            }
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
    },
})

export default {
    install: (app) => {
        const vs = viewState()
        app.config.globalProperties.vs = vs
        app.config.globalProperties.view = vs
    }
}